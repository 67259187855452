<template>
  <div>
    <b-card header="求C" header-bg-variant="tmprimary" header-text-variant="white">
      <b-form inline>
        <b-input-group prepend="电感 L">
          <b-form-input id="input_g1_l" v-model="input_g1_l" type="text" />
          <b-input-group-append>
            <b-form-select id="input_g1_lunit" v-model="input_g1_lunit">
              <option value="1">H</option>
              <option value="0.001" selected>mH</option>
              <option value="0.000001" selected>μH</option>
              <option value="0.000000001">nH</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp; 
        <b-input-group prepend="频率 f">
          <b-form-input id="input_g1_f" v-model="input_g1_f" type="text" />
          <b-input-group-append>
            <b-form-select id="input_g1_funit" v-model="input_g1_funit">
              <option value="1" selected>Hz</option>
              <option value="1000">kHz</option>
              <option value="1000000">MHz</option>
              <option value="1000000000">GHz</option>
              <option value="1000000000000">THz</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp;
        <b-input-group prepend="电容 C">
          <b-form-input id="output_g1_c" v-model="output_g1_c" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_g1_cunit" v-model="input_g1_cunit">
              <option value="1">F</option>
              <option value="0.001">mF</option>
              <option value="0.000001" selected>μF</option>
              <option value="0.000000001">nF</option>
              <option value="0.000000000001">pF</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </b-card>
  </div>
</template>
<script>
const pi = 3.14159265358979;
export default {
  data() {
    return {
      input_g1_f: "0",
      input_g1_lunit: "0.001",
      input_g1_l: "0",
      input_g1_cunit: "0.000001",
      input_g1_funit: "1"
    };
  },
  computed: {
    output_g1_c() {
      var af = Number(this.input_g1_f) * Number(this.input_g1_funit);
      var al = Number(this.input_g1_l) * Number(this.input_g1_lunit);
      var at = 1 / af
      var sqrtlc = at / 2 / pi
      var lc = sqrtlc * sqrtlc
      var ac = lc / al
      return ac / Number(this.input_g1_cunit);
    }
  }
};
</script>
<style lang="stylus">
input {
  width: 100%;
}
</style>