<template>
  <div>
    <LC001 />
    <br />
    <LC002 />
    <br />
    <LC003 />
  </div>
</template>
<script>
import LC001 from "@/views/submods/basic_lcreso/lc001.vue"
import LC002 from "@/views/submods/basic_lcreso/lc002.vue"
import LC003 from "@/views/submods/basic_lcreso/lc003.vue"
export default {
  components: { LC001, LC002, LC003 }
}
</script>